<template lang="html">
    <div class="settingContent" id="peoManagement">
		<div class="handleBox flex flex-jc-sb flex-ai-c">
			<h3 style="">{{$route.name}}</h3>
			<div class="handlebox flex flex-ai-c">
				<el-button type="primary" size="small" icon="el-icon-circle-plus-outline" @click="edit()">新建</el-button>
			</div>
		</div>
        <div class="contentBox" style="width:100%;padding:0 40px">
			<el-table :data="list" size="medium" :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" :max-height="tableHeight">
                <el-table-column prop="realname" label="姓名"></el-table-column>
                <el-table-column prop="mobile" label="手机号"></el-table-column>
                <el-table-column prop="create_time" label="创建时间"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
						<el-button-group>
						  <el-button size="mini" v-if="!scope.row.openid" type="primary" plain @click="handleBind(scope.row.id,scope.row.realname)">绑定微信</el-button>
						  <el-button size="mini" type="primary" plain @click="edit(scope.row)">编辑</el-button>
						  <el-button size="mini" type="primary" plain @click="del(scope.row.id,scope.row.realname)">删除</el-button>
						</el-button-group>
                    </template>
                </el-table-column>
            </el-table>
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

        <win :title="title" v-if="winIsShow" @winClose="winIsShow=false" @winEnter="submit">
            <el-form ref="edit" :rules="rules" :model="formData" label-width="80px">
                <el-form-item label="姓名" prop="realname">
                    <el-input placeholder="请输入管理员姓名" v-model="formData.realname"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input placeholder="请输入管理员手机号码" v-model="formData.mobile"></el-input>
                </el-form-item>
            </el-form>
        </win>
		<!-- 绑定微信 -->
		<win :title="title" v-if="bindIsShow" @winClose="winClose" :isBtn='false' :width='600'>
			<div id="login_container">
				<p class="p1">微信扫码绑定</p>
				<img :src="code" alt="">
				<!-- <div v-if="isMask" class="mask flex flex-column flex-ai-c flex-jc-c">
					二维码已过期
					<p>请<span @click="getCode()">点击刷新</span></p>
				</div> -->
				<p class="p2">二维码有效期截止 {{codeTime}}<br>过期请刷新</p>
            </div>
        </win>
    </div>
</template>

<script>
import axios from 'axios';
// import '../../plugins/login.js';
import win from "../../components/win.vue";
// import utils from '../../plugins/utils.js';
export default {
    components: {
        win
    },
    data: function() {
        return {
            search: {
                searchValue: '', //搜索值
            },
            value: {
                name: ''
            },
            title: '添加平台管理员',
            winIsShow: false,
            formData: {

            },
            tableHeight: 660,
            // 表单验证
            rules: {
                realname: [{
                    required: true,
                    message: '请输入管理员姓名',
                    trigger: 'change'
                }],
                mobile: [{
                    required: true,
                    message: '请输入管理员手机号码',
                    trigger: 'change'
                }, {
                    pattern: /^1[0-9][0-9]\d{8}$/,
                    message: '手机号格式不对',
                    trigger: 'blur'
                }],
            },
            list: [],
            code: '',
            codeTime: '',
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
            timer: null,
            isMask: false,
            bindIsShow: false,
        }
    },
    mounted: function() {
        this.init();
    },
    methods: {
        init: function() {
            this.autoHeight();
            this.getList();
        },
        //自动高度
        autoHeight: function() {
            var duoyu = 60 + 70 + 61 + 30 + 65 + 15 + 40;
            this.tableHeight = document.documentElement.clientHeight - duoyu;
            window.onresize = () => {
                this.tableHeight = document.documentElement.clientHeight - duoyu;
            }
        },
        handleBind(id, name) {
            this.getCode(id);
            this.title = '绑定 - ' + name;
            this.bindIsShow = true
        },
        winClose() {
            this.bindIsShow = false;
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
        // 获取列表
        getList: function() {
            var url = '/admin.user/indexRoot';
            this.http.post(url, {
                curr: this.curr,
                row: this.row,
            }).then(res => {
                this.list = res.list;
                this.curr = res.curr;
                this.pages = res.pages;
                this.count = res.count;
            });
        },
        //添加或修改
        edit: function(detail = false) {
            if (detail) {
                let obj = {
                    realname: detail.realname,
                    mobile: detail.mobile,
                    id: detail.id
                }
                this.formData = JSON.parse(JSON.stringify(obj));
                this.title = '编辑平台管理员';
            } else {
                this.formData = {};
                this.title = '添加平台管理员';
            }
            this.winIsShow = true;
        },
        // 绑定的二维码
        getCode(user_id) {
            this.http.post('/admin.user/rootUserBindWechatQrcode', {
                user_id
            }).then(re => {
                this.code = re.qrcode;
                this.codeTime = re.deadline_time;
                this.timer = setInterval(() => {
                    this.getState(user_id)
                }, 3000)
            })
        },
        // 二维码状态
        getState(user_id) {
            this.http.post('/admin.user/rootUserWechatBindIs', {
                user_id
            }, false, true).then(re => { //1:绑定成功
                if (Number(re.data) == 1) {
                    this.$message({
                        message: '绑定成功！',
                        type: 'success'
                    });
                    this.bindIsShow = false;
                    clearInterval(this.timer);
                }
            })
        },
        // 删除
        del(id, name) {
            this.$confirm('确定删除' + name + '吗?', '删除提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var apiUrl = "/admin.user/deleteRoot";
                this.http.post(apiUrl, {
                    id,
                }).then(res => {
                    this.getList();
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                });
            }).catch(() => {

            });
        },
        // 提交
        submit() {
            this.$refs['edit'].validate((valid) => {
                if (valid) {
                    var apiUrl = "/admin.user/rootSave"; //添加
                    this.http.post(apiUrl, this.formData).then(res => {
                        this.winIsShow = false;
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                        this.getList();
                    });
                }
            })
        },
    }
}
</script>
<style lang="less">
// .addpeobox{
// 	text-align: center;
// 	img{
// 		width: 250px;
// 	}
// }
#login_container {
    padding-top: 10px;
    position: relative;
    height: 430px;
    .p1 {
        font-size: 20px;
        text-align: center;
        color: rgb(55, 55, 55);
    }
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        // margin-top: 15px;
        border: 1px solid #E2E2E2;
        width: 300px;
        height: 300px;
    }
    .mask {
        position: absolute;
        width: 300px;
        height: 300px;
        // margin-top: 15px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border: 1px solid #E2E2E2;
        background: #ddd;
        p {
            margin-top: 10px;
            span {
                color: #40A9FF;
                cursor: pointer;
            }
        }
    }
    .p2 {
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translate(-50%,0);
        color: #373737;
        font-size: 14px;
        text-align: center;
        // padding: 7px 15px;
    }
}
</style>
